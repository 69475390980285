import jwt_decode from "jwt-decode";
import {ToastFailedMsg, ToastSuccessMsg} from "./toast";


const apiBaseUrl = process.env.REACT_APP_PUBLIC_API_URL ? process.env.REACT_APP_PUBLIC_API_URL : "http://localhost:8001"

export const request = async (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    // let token = getToken()

    let token = getToken().accessToken

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    url = apiBaseUrl + url;

    let req;
    try {
        req = await fetch(url, { method, mode: "cors", cache: "no-cache",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                ...headers,
            },
            body: JSON.stringify(payload),
        })
        await checkTokenStatus(req);    // check req
        return req;
    } catch (error) {
        // ToastFailedMsg("Can't fetch data from server")
        return {status: 500, json: () => {}, blob:()=> {}, ok: false}
    }
};


export const getToken = () => {

    let accessToken = localStorage.getItem("accessToken")
    let refreshToken = localStorage.getItem("refreshToken")
    let details = localStorage.getItem("details");
    if ((accessToken !== '' && accessToken !== undefined) && (refreshToken !== '' && refreshToken !== undefined) && (details !== '' && details !== undefined)) {
        return {accessToken, refreshToken, details}
    }
    return {accessToken, refreshToken, details}

}

export const setToken = (token: any) => {
    console.log(token);
    if (token) {
        localStorage.setItem("accessToken", token.accessToken)
        localStorage.setItem("refreshToken", token.refreshToken)
        localStorage.setItem("details", token.details)
    }
    else{
        localStorage.setItem("details", "{}")
    }
}
export const isLoggedIn = () => {
    let {accessToken, refreshToken, details} = getToken();
    if (accessToken && refreshToken) {
        return true;
    }
    // checkTokenStatus()
    return false;
}

export const unsetToken = () => {
    localStorage.setItem("accessToken", '')
    localStorage.setItem("refreshToken", '')
    localStorage.setItem("details", '')
    localStorage.removeItem("chosenType");
}



export const renewToken = async (url: string) => {
    let token = getToken().refreshToken

    console.log('renewToken', token);

    url = apiBaseUrl + url;

    return fetch(url, {
        method: "post",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
};

export const checkTokenStatus = async (response: any) => {

    if (response.status === 403) {
        // console.log('msg', 'access token expired');
       // ToastFailedMsg("Access Token Expired")

        let res = await renewToken("/auth/renew");

        if (res.status === 200) {
            let {data}: any = await res.json();
            console.log('msg', data);
            setToken({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                details :data.details,
            })

            ToastSuccessMsg("Renew Access Token")
        } else {
            // reset token
            setToken({
                accessToken: "",
                refreshToken: "",
                details:"",
                roles: "",
            })
            window.location.reload();
        }
    }
    /*let {accessToken, refreshToken}:any = getToken()
    let {exp, roles}:any = jwt_decode(accessToken);

    if(new Date(exp).getTime() > new Date().getTime()) {
        console.log('AccessToken expired, Refresh Token Is: ', refreshToken);
        // let {exp, roles}:any = jwt_decode(refreshToken);
        //todo: need to get renew access token using refresh token
        console.log('msg', 'todo: need to get renew access token using refresh token');
    }*/
}


export const decodeToken: any = () => {

    try {
        let token = getToken().accessToken;
        if (typeof token === "string") {
            let decoded = jwt_decode(token);
            return decoded;
            //console.log('get roles', decoded);
        }
    }catch (error){}

    return;
}

export const getRoles = () => {
    try {
        let {roles} = decodeToken();
        return roles
    }catch (err) {}
    return []
}

export const getLoggedInUserId = () => {
    try {
        let {userId} = decodeToken();
        return userId;
    }catch (err){}
    return;
}

export const requestFileUpload = (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    let token = getToken().accessToken;
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    url = apiBaseUrl + url;

    let req = fetch(url, {
        method,
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Access-Control-Allow-Origin": "*",
            ...headers,
        },
        body: payload,
    });

    req.then(async (response) => {
        await checkTokenStatus(response);
        return response;
    })

    return req;

};

export const post = (url: string, payload: any) => {
    return request(url, "POST", payload);
};

export const put = (url: string, payload: any) => {
    return request(url, "PUT", payload);
};

export const get = (url: string) => {
    return request(url, "GET");
};

export const getWithPayload =(url: string,payload:any) =>{
    console.log("get all the data");
  return request(url,"GET",payload) ;
};

export const postFile = (url: string, payload: any) => {
    return requestFileUpload(url, "POST", payload);
};

export const del = (url: string) => {
    return request(url, "DELETE");
};

export const patch = (url: string, payload: any) => {
    return request(url, "PATCH", payload);
};

export const deleteItem = (url: string) => {
    return request(url, "DELETE");
};

export const update = (url: string, paylad: any) => {
    return request(url, "PATCH", paylad);
};

// for pythonbackend
const apiUrl = process.env.REACT_APP_ELK_PYTHON

export const requestFileUploadPython = (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    let token = getToken().accessToken;
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    if(url == "/postCheckedAudio"){
        url = `${apiUrl}:5922/submit-listen/`;
    }
    else if( url =="/submit-conversation"){
        // url = "http://amarkantho.revesoft.com:5200/submit-conversation/";
        url = `${apiUrl}:5200/submit-conversation/`;
    }
    else if( url == "/submit-lecture"){
        url = `${apiUrl}:5300/submit-lecture/`;
        // url = `http://amarkantho.revesoft.com:5300/submit-lecture`;
    }
    else if(url == "/submit-verdict"){
        url = `${apiUrl}:7000/submit-verdict/`;
    }
    else if(url == "/submit-audio-new/"){
        url = `${apiUrl}:5911/submit-audio-new/`;
        // url = "http://amarkantho.revesoft.com:5200/bolun-test/";
    }
    else{
        url = `${apiUrl}:5911/submit-audio/`;
        // url = "http://amarkantho.revesoft.com:5200/bolun-test/";
    }

    let req = fetch(url, {
        method,
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Access-Control-Allow-Origin": "*",
            ...headers,
        },
        body: payload,
    });

    req.then(async (response) => {
        await checkTokenStatus(response);
        return response;
    })

    return req;

};

export const requestToPython = async (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    // let token = getToken()

    let token = getToken().accessToken

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }
    if(url == "/auth/login"){
        url = `${apiUrl}:5901/login/`
    }
    else if(url == "/get-scripts"){
        url = `${apiUrl}:5910/get-scripts/`
    }
    else if(url == "/getAudiosWithScriptByUserName/"){
        url = `${apiUrl}:5920/getAudiosWithScriptByUserName/`
    }
    else if(url == "/fetch-audio/"){
        url = `${apiUrl}:5921/fetch-audio/`
        // url = "http://119.148.4.20:5650/fetch-audio/"
    }
    else if(url == "/reset-password"){
        url = `${apiUrl}:5910/reset-password/`;
        // url = "http://119.148.4.20:5719/reset-password/"
    }
    else if(url == "/get-speaker-audio-time"){
        url = `${apiUrl}:5930/get-speaker-audio-time/`;
    }
    else if(url == "/get-speaker-listen-time"){
        url = `${apiUrl}:5930/get-speaker-listen-time/`;
    }
    else if(url == "/get-day-wise-duration"){
        url = `${apiUrl}:5930/get-day-wise-duration/`;
    }
    else if(url == "/get-day-wise-duration-listen"){
        url = `${apiUrl}:5930/get-day-wise-duration-listen/`;
    }
    else if(url == "/getMainDashboardStats/"){
        url = `${apiUrl}:5931/getMainDashboardStats/`;
    }
    else if(url == "/tts-evaluation"){
        // url = "http://158.101.84.230:6797/synthesizer";
        url = `${apiUrl}:6760/synthesizer`;
    }
    else if(url == "/get-verdicts"){
        url = `${apiUrl}:7001/get-verdicts/`;
    }
    else if(url == "/update-verdicts"){
        url = `${apiUrl}:7001/update-verdicts/`;
    }
    else{
        url = `${apiUrl}:5900/register/`;
    }
    let req;
    try {
        req = await fetch(url, { method, mode: "cors", cache: "no-cache",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                ...headers,
            },
            body: JSON.stringify(payload),
        })
        await checkTokenStatus(req);    // check req
        return req;
    } catch (error) {
        // ToastFailedMsg("Can't fetch data from server")
        return {status: 500, json: () => {}, blob:()=> {}, ok: false}
    }
};
export const postFiletoPython = (url: string, payload: any) => {
    return requestFileUploadPython(url, "POST", payload);
};

export const postToPython = (url: string, payload: any) => {
    return requestToPython(url, "POST", payload);
};

export const getFromPython = (url:string) =>{
    return requestToPython(url,"GET");
}
