import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import WaveSurfer from 'wavesurfer.js';
import SpectrogramPlugin from 'wavesurfer.js/dist/plugins/spectrogram';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import colormap from 'colormap';

const AudioSpectrogram = ({ audioUrl, wavesurferInstance, setHasListened }) => {
    const [showBackdrop, setShowBackdrop] = useState(false);
    const wavesurferRef = useRef(null);
    const spectrogramRef = useRef(null);
    const timelineRef = useRef(null);
    const sampleRateRef = useRef(8000);
    const fftSamples = 512;

    useEffect(() => {
        // Event listener for the spacebar to play/pause
        const handleKeyDown = (event) => {
            if (event.code === 'Space') {
                event.preventDefault();
                if (wavesurferInstance.current) {
                    if (wavesurferInstance.current.isPlaying()) {
                        wavesurferInstance.current.pause();
                    } else {
                        wavesurferInstance.current.play();
                    }
                }
            }
        };

        // Add event listener on component mount
        window.addEventListener('keydown', handleKeyDown);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        async function getAudioMetadata(url) {
            setShowBackdrop(true);
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

            // return {
            //     sampleRate: audioBuffer.sampleRate,
            //     numberOfChannels: audioBuffer.numberOfChannels,
            // };

            sampleRateRef.current = audioBuffer.sampleRate;
            setShowBackdrop(false);
        }
        getAudioMetadata(audioUrl).then(() => {

            // Generate the magma colormap
            const colors = colormap({
                colormap: 'hot',
                nshades: 256,
                format: 'float',
                //   alpha: 1
            });
            // console.log(colors)

            const wavesurfer = WaveSurfer.create({
                container: wavesurferRef.current,
                height: "auto",
                waveColor: '#ff9e44',
                progressColor: '#f65da7',
                cursorColor: 'red',
                sampleRate: sampleRateRef.current,
                url: audioUrl,
                // dragToSeek: true,
                interact: false,
                plugins: [
                    SpectrogramPlugin.create({
                        container: spectrogramRef.current,
                        fftSamples: fftSamples, // You can adjust this value for better frequency resolution
                        labels: true,
                        colorMap: colors,
                        frequencyMax: Math.floor(sampleRateRef.current / 2), // Set the maximum frequency to 21.6 kHz
                        // windowFunc: "hann"
                    }),
                    TimelinePlugin.create({
                        container: timelineRef.current,
                        timeInterval: 0.1,
                        primaryLabelInterval: 1,
                        // secondaryLabelInterval: 0.1,
                        secondaryLabelOpacity: 0.6,
                        secondaryLabelSpacing: 5
                    })
                ]
            });

            // // Load audio file
            // wavesurfer.load(audioUrl);

            wavesurfer.once('finish', () => setHasListened(true));

            // Store the instance in a ref
            wavesurferInstance.current = wavesurfer;

            // Play/pause on waveform click
            const handleWaveformClick = () => {
                if (wavesurfer.isPlaying()) {
                    wavesurfer.pause();
                } else {
                    wavesurfer.play();
                }
            };

            // Play/pause on spectrogram click
            const handleSpectrogramClick = () => {
                if (wavesurfer.isPlaying()) {
                    wavesurfer.pause();
                } else {
                    wavesurfer.play();
                }
            };

            wavesurferRef.current.addEventListener('click', handleWaveformClick);
            spectrogramRef.current.addEventListener('click', handleSpectrogramClick);
        }).catch(error => console.log(error));
    }, [audioUrl]);

    return (
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div ref={timelineRef} style={{ width: '100%', height: '20px' }}></div>
            <div ref={wavesurferRef} style={{ width: '100%', height: '20vh', backgroundColor: "#0e0918" }}></div>
            <div ref={spectrogramRef} style={{ width: '100%', height: `${Math.floor(fftSamples / 2)}px` }}></div>
        </Box>
    );
};

export default AudioSpectrogram;
