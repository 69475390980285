import { useEffect, useState } from "react";

export default function BoldMiddleText({ text }: any) {
  const [modifiedTextObjList, setModifiedTextObjList]: any = useState([]);
  useEffect(() => {
    if (text != undefined && text !== "") {
      console.log(text);
      // const text = "I am very <happy right now>!";
      const regex = /(<[^>]+>)/; // Regex pattern to match <...>
      const array = text.split(regex);

      console.log(array);

      // let array = text.split(" ");
      // console.log(array);
      // const regex = /[<>]/;
      let tempTextObjList: any = [];
      for (let i = 0; i < array.length; i++) {
        let containsMatch = regex.test(array[i]);
        console.log(array[i], containsMatch);
        // let textObj = {};
        if (containsMatch) {
          let temp = array[i].replace(/[<>]/g, "");
          console.log(temp);
          let textObj = {
            text: temp,
            bold: true,
          };
          tempTextObjList.push(textObj);
        } else {
          let textObj = {
            text: array[i],
            bold: false,
          };
          tempTextObjList.push(textObj);
        }
      }
      setModifiedTextObjList(tempTextObjList);
    }
  }, [text]);

  // useEffect(() => {
  //   console.log(modifiedTextObjList);

  // }, [modifiedTextObjList])

  return (
    <>
      {modifiedTextObjList.map((modifiedTextObj: any, index: any) => {
        if (modifiedTextObj.bold) {
          return <b key={index} style={{color:"#0000b3"}}>{modifiedTextObj.text}</b>;
        } else {
          return <span key={index}>{modifiedTextObj.text}</span>;
        }
      })}
    </>
  );
}
