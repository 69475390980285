import { Box, Typography } from "@mui/material";
import revesystems from "./images/revesystems.png";
import { ReactComponent as BanglaSvg } from './images/bangla.svg';
import { ReactComponent as BccSvg } from './images/bcc_logo.svg';
import { ReactComponent as IctSvg } from './images/ICT.svg';
import { ReactComponent as EblictSvg } from './images/EBLICT.svg';
import { useHistory } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

function Footer() {
  const history = useHistory();
  const hasMdReached = useMediaQuery('(max-width:900px)');
  return (
    hasMdReached ? <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        border: "0px solid blue",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* <img
        src={bangla}
        alt=""
        style={{ width: "auto", height: "auto", maxWidth: "20%", maxHeight: "90%" }}
      /> */}
      <BanglaSvg style={{ width: "auto", height: "auto", maxWidth: "20%", maxHeight: "90%", cursor: "pointer" }} onClick={() => history.push("https://bangla.gov.bd/", "_blank")} />

      {/* <img
        src={bcc}
        alt=""
        style={{ width: "auto", height: "auto", maxWidth: "20%", maxHeight: "90%" }}
      /> */}
      <BccSvg style={{ width: "auto", height: "auto", maxWidth: "20%", maxHeight: "90%", cursor: "pointer" }} onClick={() => history.push("https://bcc.gov.bd/", "_blank")} />

      {/* <img
        src={ict}
        alt=""
        style={{ width: "auto", height: "auto", maxWidth: "20%", maxHeight: "90%" }}
      /> */}
      <IctSvg style={{ width: "auto", height: "auto", maxWidth: "20%", maxHeight: "90%", cursor: "pointer" }} onClick={() => history.push("https://ictd.gov.bd/", "_blank")} />

      <img
        src={revesystems}
        alt=""
        style={{ width: "auto", height: "auto", maxWidth: "20%", maxHeight: "90%" }}
      />
    </Box >
      :
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          border: "0px solid blue",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box sx={{ width: "36%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", paddingLeft: "5px" }}>
          <img
            src={revesystems}
            alt=""
            style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "50%" }}
          />
        </Box>

        <Box sx={{ display: "flex", width: "28%", height: "100%", alignItems: "center", justifyContent: "center", border: "0px solid red" }}>
          <Typography
            sx={{
              border: "0px solid red",
              fontSize: "80%",
              textAlign: "center",
            }}
          >
            © ২০২৩ সর্বসত্ব সংরক্ষিত
          </Typography>
        </Box>

        <Box sx={{ width: "36%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: 3, border: "0px solid blue", paddingRight: "10px" }}>
            <BanglaSvg style={{ height: "75%", width: "auto", cursor: "pointer" }} onClick={() => window.open("https://bangla.gov.bd/", '_blank', 'noopener,noreferrer')}/>

            <BccSvg style={{ height: "75%", width: "auto", cursor: "pointer" }} onClick={() => window.open("https://bcc.gov.bd/", '_blank', 'noopener,noreferrer')}/>

            <IctSvg style={{ height: "75%", width: "auto", cursor: "pointer" }} onClick={() => window.open("https://ictd.gov.bd/", '_blank', 'noopener,noreferrer')}/>
        </Box>
      </Box >
  );
}

export default Footer;
