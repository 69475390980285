import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import InsightsIcon from '@mui/icons-material/Insights';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getLoggedInUserId, unsetToken } from "../../common/http";
import { ToastSuccessMsg } from "../../common/toast";
// import homelogo from "./images/stts/homelogo.png";
import { ReactComponent as SukonthoSvg } from './images/stts/sukontho.svg';

const drawerWidth = 240;
const navItems = [
  { text: "হোম", link: "/" },
  { text: "অংশ নিন", link: "/reve-crowd-bn-bolun/" },
  { text: "আমাদের সম্পর্কে", link: "/about/" },
];

export default function NavbarResponsive() {
  const [mobileOpen, setMobileOpen] = useState(false);
  let [userId, setUserId]: any = useState(getLoggedInUserId);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const history = useHistory();

  const onLogout = (e: any) => {
    // eslint-disable-next-line no-restricted-globals
    let con = window.confirm("লগ আউট নিশ্চিত করুন");
    if (con) {
      unsetToken();
      ToastSuccessMsg("লগ আউট সম্পন্ন হয়েছে");
      setTimeout(() => { history.push("/"); window.location.reload(); }, 2500);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", border: "0px solid green" }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center", fontFamily: "Noto Serif Bengali, serif", fontSize: "125%", fontWeight: "bold" }}
              href={(process.env.REACT_APP_ROUTER_BASE || "") + item.link}
            >
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItem key={"আপনার প্রতিক্রিয়া"} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfsnCLSf_wssSkFE_OQo5VG-lGsKLzc7dqD3fdlaygZuafsYg/viewform"
            target="_blank"
          >
            <ListItemText primary={"আপনার প্রতিক্রিয়া"} />
          </ListItemButton>
        </ListItem> */}
        <ListItem key={"ড্যাশবোর্ড"} disablePadding>
          <ListItemButton
            href={(process.env.REACT_APP_ROUTER_BASE || "") + "/user-dashboard/"}
            sx={{ textAlign: "center", color: "#1976D2" }}
          >
            <ListItemText primary={"ড্যাশবোর্ড"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"প্রবেশ করুন"} disablePadding>
          {userId === undefined ? (
            <ListItemButton
              href={(process.env.REACT_APP_ROUTER_BASE || "") + "/log-in/"}
              sx={{ textAlign: "center", color: "#44AF4F" }}
            >
              <ListItemText primary={"প্রবেশ করুন"} />
            </ListItemButton>
          ) : (
            <ListItemButton
              sx={{ textAlign: "center", color: "#D32F2F" }}
              onClick={onLogout}
            >
              <ListItemText primary={"লগ আউট"} />
            </ListItemButton>
          )}
        </ListItem>
      </List>
    </Box>
  );

  const container =
    typeof window !== "undefined" ? () => window.document.body : undefined;

  const hasMdReached = useMediaQuery('(max-width:900px)');

  return (
    <Box sx={{ height: "100%", p: 0, m: 0, border: "0px solid green" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="relative"
        sx={{ background: "#FFFFFF", color: "black", m: 0, p: 0, height: "100%", width: "100%", border: "0px solid red" }}
      >
        <Toolbar disableGutters variant="dense" sx={{ justifyContent: "space-between", alignItems: "center", height: "100%", minHeight: "100%", maxHeight: "100%", width: "100%", minWidth: "100%", maxWidth: "100%", m: 0, px: 2, py: 0, border: "0px solid blue" }}>
          {/* <Typography component="div">
            <a href={(process.env.REACT_APP_ROUTER_BASE || "") + "/"}>
              <img src={homelogo} alt="Logo" style={{
                cursor: "pointer",
                height: "100%",
                width: "auto",
              }} />
            </a>
          </Typography> */}

          <Box sx={{ border: "0px solid red", height: "100%", width: { xs: "50%", sm: "20%" }, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", gap: 2 }}>
            <SukonthoSvg style={{ height: "92%", width: "auto", cursor: "pointer" }} onClick={() => history.push((process.env.REACT_APP_ROUTER_BASE || "") + "/")} />
            <Typography
              noWrap
              sx={{
                display: {xs: "none", md: "block"},
                fontFamily: "Alkatra, cursive", fontSize: "200%", fontWeight: "bold",
                // letterSpacing: '.3rem',
                color: '#006B43',
                cursor: "pointer"
              }}
              onClick={() => history.push((process.env.REACT_APP_ROUTER_BASE || "") + "/")}
            >
              সুকণ্ঠ
            </Typography>
          </Box>


          <Box sx={{ display: { xs: "none", sm: "flex" }, width: { xs: "0%", sm: "60%" }, flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 5 }}>
            {navItems.map((item) => (
              <React.Fragment key={item.text}>
                <Button
                  sx={{
                    color: "#4C4C4C",
                    fontFamily: "Noto Serif Bengali, serif", fontSize: "125%", fontWeight: "bold"
                  }}
                  href={(process.env.REACT_APP_ROUTER_BASE || "") + item.link}
                >
                  {item.text}
                </Button>
                {/* <span>|</span> */}
              </React.Fragment>
            ))}
            {/* <Button
              key={"আপনার প্রতিক্রিয়া"}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfsnCLSf_wssSkFE_OQo5VG-lGsKLzc7dqD3fdlaygZuafsYg/viewform"
              target="_blank"
              sx={{ color: "#4C4C4C", fontFamily: "Noto Serif Bengali, serif", fontSize: "125%", fontWeight: "bold" }}
            >
              আপনার প্রতিক্রিয়া
            </Button> */}
          </Box>

          <Box sx={{ display: { xs: "none", sm: "flex" }, width: { xs: "0%", sm: "20%" }, flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
            {userId === undefined ? (
              hasMdReached ?
                <Tooltip title="প্রবেশ করুন" arrow>
                  <IconButton color="success" onClick={() => history.push((process.env.REACT_APP_ROUTER_BASE || "") + "/log-in/")}>
                    <LoginIcon sx={{ fontSize: "125%" }} />
                  </IconButton>
                </Tooltip> :
                <Button
                  variant="contained"
                  sx={{ m: 0, paddingX: "8px", paddingY: "2px", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold" }}
                  color="success"
                  onClick={() => history.push((process.env.REACT_APP_ROUTER_BASE || "") + "/log-in/")}
                >
                  প্রবেশ
                </Button>
            ) : (
              <Box sx={{ height: "100%", width: "100%", gap: 2, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                {hasMdReached ? <Tooltip title="ড্যাশবোর্ড" arrow>
                  <IconButton color="info" onClick={() => history.push((process.env.REACT_APP_ROUTER_BASE || "") + "/user-dashboard/")}>
                    <DashboardIcon sx={{ fontSize: "125%" }} />
                  </IconButton>
                </Tooltip> :
                  <Button
                    variant="contained"
                    sx={{ m: 0, paddingX: "8px", paddingY: "2px", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold" }}
                    color="primary"
                    onClick={() => history.push((process.env.REACT_APP_ROUTER_BASE || "") + "/user-dashboard/")}
                  >
                    ড্যাশবোর্ড
                  </Button>
                }
                {hasMdReached ? <Tooltip title="লগ আউট" arrow>
                  <IconButton color="error" onClick={onLogout}>
                    <LogoutIcon sx={{ fontSize: "125%" }} />
                  </IconButton>
                </Tooltip>
                  :
                  <Button
                    variant="contained"
                    sx={{ m: 0, paddingX: "8px", paddingY: "2px", fontFamily: "Noto Serif Bengali, serif", fontWeight: "bold" }}
                    color="error"
                    onClick={onLogout}
                  >
                    বাহির
                  </Button>
                }
              </Box>
            )}
          </Box>

          <IconButton
            color="info"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="end"
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
