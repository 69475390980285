import { useEffect, useState } from "react";
// import MenuComponent from "./Menu.Component";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import NavbarResponsive from "./NavbarResponsive";
import { BarChart } from '@mui/x-charts/BarChart';
import Tooltip from "@mui/material/Tooltip";
import { axisClasses } from '@mui/x-charts';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Footer from "./Footer";

import leftwave from "./images/stts/leftwave.png";
import rightwave from "./images/stts/rightwave.png";

import { getMainDashboardStats } from "./crowd_source.service";

export default function CrowdDashBoard() {
  // @ts-ignore
  // @ts-ignore

  let [graphData, setGraphData]: any = useState(null);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [bolunYears, setBolunYears]: any = useState([]);
  const [bolunYear, setBolunYear]: any = useState('');
  const [shununYears, setShununYears]: any = useState([]);
  const [shununYear, setShununYear]: any = useState('');

  const [bolunDataset, setBolunDataset]: any = useState([]);
  const [shununDataset, setShununDataset]: any = useState([]);

  const BnToEn = (n: any) => n.replace(/[০-৯]/g, (d: any) => "০১২৩৪৫৬৭৮৯".indexOf(d));
  const EnToBn = (n: any) => n.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);

  const formatHourMinSec = (hours: number) => {
    let totalSeconds = hours * 3600
    hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60)

    // let seconds = totalSeconds - hours * 3600 - minutes * 60

    // // if (seconds >= 1) {
    // //     seconds = Math.round(seconds)
    // // } else {
    // //     seconds = parseFloat(seconds.toFixed(3))
    // // }
    // seconds = Math.round(seconds)

    // return `${("0" + hour).slice(-2)} hr ${("0" + minutes).slice(-2)} min ${("0" + seconds).slice(-2)} sec`
    // return `${hours > 0 ? EnToBn(hours.toString()) + ' ঘণ্টা ' : ''}${minutes > 0 ? EnToBn(minutes.toString()) + ' মিনিট ' : ''}${seconds > 0 ? EnToBn(seconds.toString()) + ' সেকেন্ড' : ''}`
    return `${hours > 0 ? EnToBn(hours.toString()) + ' ঘণ্টা ' : ''}${minutes > 0 ? EnToBn(minutes.toString()) + ' মিনিট ' : ''}`
  }

  const chartSettings = {
    // width: 500,
    // height: 300,
    // yAxis: [
    //   {
    //     label: 'rainfall (mm)',
    //   },
    // ],
    // sx: {
    //   [`.${axisClasses.left} .${axisClasses.label}`]: {
    //     transform: 'rotate(-90deg) translate(0px, -20px)',
    //   },
    // },
  };

  useEffect(() => {
    (async () => {
      await fetchTotalDurationAndSpeakers();
    })();
  }, []);

  const fetchTotalDurationAndSpeakers = async () => {
    let res = await getMainDashboardStats();
    if (res.status == 200) {
      let responseBody = await res.json();
      setGraphData((prevData: any) => responseBody);
      setBolunYears(Object.keys(responseBody["monthwise-bolun"]));
      setShununYears(Object.keys(responseBody["monthwise-shunun"]));
    }
  };

  useEffect(() => {
    console.log("GRAPH DATA: ", graphData);
  }, [graphData]);

  useEffect(() => {
    console.log("BOLUN YEARS: ", bolunYears);
    if (bolunYear == "" && bolunYears.length > 0) { setBolunYear(bolunYears[bolunYears.length - 1]) }
  }, [bolunYears]);

  useEffect(() => {
    console.log("BOLUN YEAR: ", bolunYear);
    if (graphData && bolunYear != "") {
      setBolunDataset(months.map((month: any) => {
        return {
          "month": month,
          "total-recorded": graphData["monthwise-bolun"][bolunYear][month] / 3600,
          "total-accepted": graphData["monthwise-bolun-accepted"][bolunYear][month] / 3600,
        }
      }));
    }
  }, [bolunYear]);

  useEffect(() => {
    console.log("BOLUN DATASET: ", bolunDataset)
  }, [bolunDataset]);

  useEffect(() => {
    console.log("SHUNUN YEARS: ", shununYears)
    if (shununYear == "" && shununYears.length > 0) { setShununYear(shununYears[shununYears.length - 1]) }
  }, [shununYears]);

  useEffect(() => {
    console.log("SHUNUN YEAR: ", shununYear);
    if (graphData && shununYear != "") {
      setShununDataset(months.map((month: any) => {
        return {
          "month": month,
          "total-listened": graphData["monthwise-shunun"][shununYear][month] / 3600,
          "total-accepted": graphData["monthwise-shunun-accepted"][shununYear][month] / 3600,
        }
      }));
    }
  }, [shununYear]);

  useEffect(() => {
    console.log("SHUNUN DATASET: ", shununDataset)
  }, [shununDataset]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    //console.log("payload ",`${payload[0].value}`);
    function getDecimalPart(num: any) {
      if (Number.isInteger(num)) {
        return 0;
      }

      const decimalStr = num.toString().split(".")[1];
      return Number(decimalStr);
    }
    if (active && payload && payload.length) {
      var ran = String(Math.floor(payload[0].value));
      var decimalPart = String(payload[0].value).split(".")[1];
      // var remainder=parseFloat("0."+String(getDecimalPart(payload[0].value)))/10;
      var remainder = parseFloat("0." + decimalPart);
      var minute = String(Math.floor(remainder * 60));
      // @ts-ignore
      var payloadValue = ran.getDigitBanglaFromEnglish();
      // @ts-ignore
      var payloadValue2 = minute.getDigitBanglaFromEnglish();
      console.log("payload ", payloadValue2);
      return (
        <div className="custom-tooltip">
          <p className="intro">
            {payloadValue} ঘণ্টা {payloadValue2} মিনিট
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Box sx={{
        height: { xs: "150vh", md: "100vh" }, weight: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top",
        // backgroundColor: "#DFE6F5",
        // backgroundImage: `url(${homepageone})`, backgroundSize: "contain"
        backgroundImage: "linear-gradient(to bottom, #e8f2fc, #FFFFFF, #f6eff5)"
      }}>
        <Box sx={{ width: "100%", height: { xs: "4%", md: "8%" }, border: "0px solid blue" }}>
          <NavbarResponsive />
        </Box>
        <Box sx={{ width: "100%", height: { xs: "46%", md: "43%" }, display: "flex", flexDirection: { xs: "column", md: "row" }, border: "0px solid red" }}>
          <Box sx={{ width: { xs: "100%", md: "50%" }, height: { xs: "20%", md: "100%" }, border: "0px solid red", display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "75%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "25px" }}>
              <img
                src={leftwave}
                alt=""
                style={{ height: "64%", width: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box sx={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              {/* <Tooltip title="আপনার কথা দিন" arrow> */}
              <IconButton sx={{ m: 0, p: 0 }} onClick={() =>
                window.location.replace(
                  window.location.origin + "/reve-crowd-bn-bolun/"
                )
              }>
                <MicIcon color="error" sx={{ fontSize: { sx: "150%", md: "200%" } }} />
              </IconButton>
              {/* </Tooltip> */}
              <Typography
                sx={{
                  color: '#000000', // #006B43
                  fontSize: { sx: "120%", md: "150%" },
                  fontWeight: "bold",
                  fontFamily: "Noto Serif Bengali, serif"
                }}
              >
                বলুন
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "50%" }, height: { xs: "80%", md: "100%" }, border: "0px solid red", display: "flex", flexDirection: "column", paddingTop: "5px" }}>
            <Box sx={{
              width: "100%", height: "15%", border: "0px solid red", display: "flex", flexDirection: "row",
              // backgroundColor: "#F9E1FF",
              backgroundImage: "linear-gradient(to right, #dabed5, #f6eff5, #dabed5)",
              boxShadow: "0px -3px 3px #834a79, 0px 0px 3px #834a79",
              borderRadius: "25px 25px 0px 0px"
            }}>
              <Box sx={{ width: "20%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "75%", fontWeight: "bold" }}>
                  অডিও রেকর্ডকারী
                </span>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "80%", fontWeight: "bolder" }}>
                  {graphData ? `${EnToBn(graphData["total-actors-bolun"].toString())} জন` : 0}
                </span>
              </Box>
              <Box sx={{ width: "20%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "75%", fontWeight: "bold" }}>
                  মোট রেকর্ডকৃত
                </span>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "80%", fontWeight: "bolder" }}>
                  {graphData ? formatHourMinSec(graphData["total-time-bolun"] / 3600) : 0}
                </span>
              </Box>
              <Box sx={{ width: "20%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "75%", fontWeight: "bold" }}>
                  মোট গ্রহণযোগ্য
                </span>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "80%", fontWeight: "bolder" }}>
                  {graphData ? formatHourMinSec(graphData["total-accepted-bolun"] / 3600) : 0}
                </span>
              </Box>
              <Box sx={{ width: "20%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "75%", fontWeight: "bold" }}>
                  মোট সংগৃহীত
                </span>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "80%", fontWeight: "bolder" }}>
                  {graphData ? formatHourMinSec(graphData["total-collected"] / 3600) : 0}
                </span>
              </Box>
              <Box sx={{ width: "20%", height: "100%", border: "0px solid green", paddingTop: "5px" }}>
                <FormControl sx={{ m: 0, width: "100%" }} size="small">
                  <InputLabel>সাল</InputLabel>
                  <Select
                    value={bolunYear}
                    label="Year"
                    onChange={(event: SelectChangeEvent) => {
                      setBolunYear(event.target.value);
                    }}
                  >
                    {
                      bolunYears.map((year: any) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{
              width: "100%", height: "85%", border: "0px solid red",
              boxShadow: "-3px 0px 3px #834a79, 3px 3px 3px #834a79",
              borderRadius: "0px 0px 25px 25px",
            }}>
              {
                bolunDataset.length > 0 ?
                  <BarChart
                    // width={500}
                    // height={300}
                    margin={{ top: 12, right: 12, bottom: 36, left: 32 }}
                    padding={0}
                    sx={{
                      width: "100%",
                      height: "100%",
                      // backgroundColor: "#F9FBFF",
                      backgroundImage: "linear-gradient(to right, #f6eff5, #FFFFFF, #f6eff5)",
                      m: 0, p: 0,
                      borderRadius: "0px 0px 25px 25px",
                      border: "0px solid #0e0918"
                      // '--ChartsLegend-itemWidth': '200px',

                    }}
                    dataset={bolunDataset}
                    xAxis={[{ dataKey: "month", scaleType: 'band', barGapRatio: 0 }]}
                    series={[
                      { dataKey: "total-recorded", label: 'মোট রেকর্ডকৃত সময়', id: 'recorded', color: "#834a79", valueFormatter: formatHourMinSec },
                      { dataKey: "total-accepted", label: 'মোট গ্রহণযোগ্য সময়', id: 'accepted', color: "#bf8db6", valueFormatter: formatHourMinSec },
                    ]}
                    axisHighlight={{ x: "band", y: "none" }}
                    legend={{ hidden: true }}
                  // {...chartSettings}
                  /> : null
              }
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: "100%", height: { xs: "46%", md: "43%" }, display: "flex", flexDirection: { xs: "column", md: "row" }, border: "0px solid red" }}>
          <Box sx={{ display: { xs: "flex", md: "none" }, width: { xs: "100%", md: "0%" }, height: { xs: "20%", md: "0%" }, border: "0px solid red" }}>
            <Box sx={{ width: "75%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "25px" }}>
              <img
                src={rightwave}
                alt=""
                style={{ height: "64%", width: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box sx={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <IconButton sx={{ m: 0, p: 0 }} onClick={() =>
                window.location.replace(
                  window.location.origin + "/reve-crowd-bn-shunun/"
                )
              }>
                <HeadphonesIcon sx={{ fontSize: { sx: "150%", md: "200%" }, color: "#1566b7" }} />
              </IconButton>
              <Typography color="#000000" // #004EA0
                sx={{
                  fontSize: { sx: "120%", md: "150%" },
                  fontWeight: "bold",
                  fontFamily: "Noto Serif Bengali, serif"
                }}
              >
                শুনুন
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "50%" }, height: { xs: "80%", md: "100%" }, border: "0px solid red", display: "flex", flexDirection: "column", paddingBottom: "5px" }}>
            <Box sx={{
              width: "100%", height: "15%", border: "0px solid red", display: "flex", flexDirection: "row",
              // backgroundColor: "#C8EAFB",
              backgroundImage: "linear-gradient(to right, #8dbff2, #e8f2fc, #8dbff2)",
              boxShadow: "0px -3px 3px #328ce7, 0px 0px 3px #328ce7",
              borderRadius: "25px 25px 0px 0px"
            }}>
              <Box sx={{ width: "25%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "75%", fontWeight: "bold" }}>
                  অডিও যাচাইকারী
                </span>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "80%", fontWeight: "bolder" }}>
                  {graphData ? `${EnToBn(graphData["total-actors-shunun"].toString())} জন` : 0}
                </span>
              </Box>
              <Box sx={{ width: "25%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "75%", fontWeight: "bold" }}>
                  মোট যাচাইকৃত
                </span>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "80%", fontWeight: "bolder" }}>
                  {graphData ? formatHourMinSec(graphData["total-time-shunun"] / 3600) : 0}
                </span>
              </Box>
              <Box sx={{ width: "25%", height: "100%", border: "0px solid green", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "75%", fontWeight: "bold" }}>
                  মোট গ্রহণযোগ্য
                </span>
                <span style={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "80%", fontWeight: "bolder" }}>
                  {graphData ? formatHourMinSec(graphData["total-accepted-shunun"] / 3600) : 0}
                </span>
              </Box>

              <Box sx={{ width: "25%", height: "100%", border: "0px solid green", paddingTop: "5px" }}>
                <FormControl sx={{ m: 0, width: "100%" }} size="small">
                  <InputLabel>সাল</InputLabel>
                  <Select
                    value={shununYear}
                    label="Year"
                    onChange={(event: SelectChangeEvent) => {
                      setShununYear(event.target.value);
                    }}
                  >
                    {
                      shununYears.map((year: any) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box sx={{
              width: "100%", height: "85%", border: "0px solid red",
              boxShadow: "-3px 0px 3px #328ce7, 3px 3px 3px #328ce7",
              borderRadius: "0px 0px 25px 25px"
            }}>
              {
                shununDataset.length > 0 ?
                  <BarChart
                    // width={500}
                    // height={300}
                    margin={{ top: 12, right: 12, bottom: 36, left: 32 }}
                    padding={0}
                    sx={{
                      width: "100%",
                      height: "100%",
                      // backgroundColor: "#F9FBFF",
                      backgroundImage: "linear-gradient(to right, #e8f2fc, #FFFFFF, #e8f2fc)",
                      m: 0, p: 0,
                      borderRadius: "0px 0px 25px 25px"
                      // '--ChartsLegend-itemWidth': '200px',

                    }}
                    dataset={shununDataset}
                    xAxis={[{ dataKey: "month", scaleType: 'band', barGapRatio: 0 }]}
                    series={[
                      { dataKey: "total-listened", label: 'মোট যাচাইকৃত সময়', id: 'listened', color: "#328ce7", valueFormatter: formatHourMinSec },
                      { dataKey: "total-accepted", label: 'মোট গ্রহণযোগ্য সময়', id: 'accepted', color: "#8dbff2", valueFormatter: formatHourMinSec },
                    ]}
                    axisHighlight={{ x: "band", y: "none" }}
                    legend={{ hidden: true }}
                  // {...chartSettings}
                  /> : null
              }
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, width: { xs: "0%", md: "50%" }, height: { xs: "0%", md: "100%" }, border: "0px solid red" }}>
            <Box sx={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <IconButton sx={{ m: 0, p: 0 }} onClick={() =>
                window.location.replace(
                  window.location.origin + "/reve-crowd-bn-shunun/"
                )
              }>
                <HeadphonesIcon sx={{ fontSize: { sx: "150%", md: "200%" }, color: "#1566b7" }} />
              </IconButton>
              <Typography color="#000000" // #004EA0
                sx={{
                  fontSize: { sx: "120%", md: "150%" },
                  fontWeight: "bold",
                  fontFamily: "Noto Serif Bengali, serif"
                }}
              >
                শুনুন
              </Typography>
            </Box>
            <Box sx={{ width: "75%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingRight: "25px" }}>
              <img
                src={rightwave}
                alt=""
                style={{ height: "64%", width: "auto", maxWidth: "100%" }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", width: { xs: "100%", md: "100%" }, height: { xs: "4%", md: "6%" }, border: "0px solid blue" }}>
          <Footer />
        </Box>
      </Box >
    </>
  );
}
