import Box from '@mui/material/Box';
import BolunComponentNew from "./Bolun.component(refactored)";
import NavbarResponsive from "./NavbarResponsive";
import Footer from "./Footer";

export default function IndexBolunNew() {
  return (
    <>
      <Box sx={{ height: "100vh", weight: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top", backgroundColor: "#DFE6F5" }}>
        <Box sx={{width: "100%", height: {xs: "6.5%", sm: "8%"}, border: "0px solid blue"}}>
          <NavbarResponsive />
        </Box>
        <Box sx={{width: "100%", height: {xs: "87.5%", sm: "86%"}, border: "0px solid red"}}>
          <BolunComponentNew />
        </Box>
        <Box sx={{display: {xs: "none", sm: "flex"}, width: {xs: "0%", sm: "100%"}, height: {xs: "0%", sm: "6%"}, border: "0px solid blue"}}>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
