import {del, get, post, postFile, put, getWithPayload, postToPython, postFiletoPython, getFromPython} from "../../common/http";

export const registrationUser = async (formData: any) => {
    return postToPython("/auth/register", formData)
};


// export const getCrowdScripts = (id:any) => {
//     return get(`/api/v1/get-crowd-script/${id}`);
// };
export const getCrowdScripts = (id:any, type:any) => {
    return postToPython(`/get-scripts`,{"username":id,"sentencetype": type});
};

export const getScript = (value:any) => {
    return get(`/api/v1/getScriptById?id=${value}`);
};

export const getAudioUrl = () => {
    return get(`/api/v1/getAudios`);
};

export const getAudioAndScripts = () => {
    return get(`/api/v1/getAudiosWithScript`);
};

//j_aded
export const getAudioAndScriptsByUserName = (speaker_id: any, listener_id: any) => {
    // return get(`/api/v1/getAudiosWithScriptByUserName/${userName}`);
    console.log(speaker_id, listener_id)
    return postToPython(`/getAudiosWithScriptByUserName/`,{"speaker_id": speaker_id, "listener_id": listener_id});
};
//j_aded


export const postAudio = (data: any) => {
    // return postFile("/api/v1/postCheckedAudio", data);
    return postFiletoPython("/postCheckedAudio",data);
};

export const getTotalAudio = () => {
    return get(`/api/v1/get-speaker-audio`);
};

export const getTotalTime = (speakerId:any) => {
    return postToPython(`/get-speaker-audio-time`,{"id": speakerId});
};

export const getTotalListeningTime = (listenerId:any) => {
    return postToPython(`/get-speaker-listen-time`,{"id":listenerId});

};

export const getAdminTableData = (type:any) => {
    return get(`/api/v1/get-table/${type}`);
}

export const getAdminTableDataPaginatedWithSearch = (type:any="speak", pageNumber:Number=1, pageSize:Number=10, string:any="") => {
    return get(`/api/v1/getAdminTablePaginatedWithSearch?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&string=${string}`);
}

export const getTotalDurationAndPage = () => {
    return get(`/api/v1/getTotalDurationAndPage`);
}

export const getAdminTableSortedAsc = (type:any="speak", pageNumber:Number=1, pageSize:Number=10, string:any="") => {
    return get(`/api/v1/getAdminTableSortedAsc?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&string=${string}`);
}

export const getAdminTableSortedDesc = (type:any="speak", pageNumber:Number=1, pageSize:Number=10, string:any="") => {
    return get(`/api/v1/getAdminTableSortedDesc?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&string=${string}`);
}

export const getDayWiseDuration = (Id:any, Month:any, Year:any) => {
    // return get(`/api/v1/get-day-wise-duration?id=${id}&month=${month}`);
    const data = {id : Id, month : Month, year:Year}

    return postToPython(`/get-day-wise-duration`,data)
}

export const getDayWiseDurationListen = (Id:any, Month:any, Year:any) => {
    // return get(`/api/v1/get-day-wise-duration-listen?id=${id}&month=${month}`);
    const data = {id : Id, month : Month, year:Year}

    return postToPython(`/get-day-wise-duration-listen`,data)
}

export const getMonthWiseDuration = () => {
    return get(`/api/v1/get-month-wise-duration`);
}

export const getSpeechReport = (arg: any) => {
    console.log("arg: ", arg);
    return post("/api/v1/speech-report", arg);
}

export const getUserReport = (arg: any) => {
    console.log("arg: ", arg);
    return post("/api/v1/user-report", arg);
}

export const getShununAudio = (arg: any) => {
    console.log("arg: ", arg);
    // return post("/api/v1/get-shunun-audio", arg);
    return postToPython("/fetch-audio/", arg);
}

export const getMainDashboardStats = () =>{
    return getFromPython("/getMainDashboardStats/");
}