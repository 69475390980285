import React from "react";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl_logo from "./images/bccl_ogo.png";
import ict_logo from "./images/ict_logo.png";
// import "./home.scss";
import bccl from "./images/bccl_ogo.png";
import ict from "./images/ict_logo.png";
import mulpata from "./images/stts/mulpata.png";

import NavbarResponsive from "./NavbarResponsive";
import Footer from "./Footer";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DiamondIcon from '@mui/icons-material/Diamond';
import EastIcon from '@mui/icons-material/East';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontFamily: 'Alkatra, cursive',
        fontSize: "125%",
        // fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Noto Serif Bengali, serif',
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// function generate(element: React.ReactElement) {
//     return [0, 1, 2].map((value) =>
//         React.cloneElement(element, {
//             key: value,
//         }),
//     );
// }

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const readingRules = [
    { "primary_text": "স্ক্রিপ্টে যা লেখা আছে হুবহু তাই পড়ুন", "secondary_text": "" },
    { "primary_text": "স্ক্রিপ্ট পড়ার সময় তাড়াহুড়ো করবেন না", "secondary_text": "" },
    { "primary_text": "খুব ধীরে বা খুব দ্রুত পড়া গ্রহণযোগ্য হবে না", "secondary_text": "" },
    { "primary_text": "বিরাম চিহ্নের ক্ষেত্রে বাংলা ভাষার নিয়ম অনুসারে প্রয়োজনীয় বিরতি বজায় রাখুন ( । ? ! , ; : — :— )", "secondary_text": "" },
    { "primary_text": "বাক্যের চিহ্নিত অংশে যথাযথ ইনটোনেশন দিন", "secondary_text": "" },
    { "primary_text": "উল্লিখিত ট্যাগ অনুযায়ী পড়ার স্টাইল অনুসরণ করুন — নিরপেক্ষ (নিউট্রাল) / বিস্ময়সূচক / প্রশ্নবোধক ইত্যাদি", "secondary_text": "" },
    { "primary_text": "পড়ার সময় মাইক্রোফোনের অবস্থান মুখ থেকে কিছুটা দূরে রাখুন", "secondary_text": "" },
    { "primary_text": "ডিভাইসের অডিও রেকর্ড সেটিং অবশ্যই 48 KHz-এ রাখবেন", "secondary_text": "" },
    { "primary_text": "ভয়েস রেকর্ডের সময় পুরোপুরি নিঃশব্দ পরিবেশ নিশ্চিত করুন", "secondary_text": "" },
    { "primary_text": "অনুগ্রহ করে একই রেকর্ডিং প্যাটার্ন নিশ্চিত করার চেষ্টা করুন (আপনি প্রথম দিনে যে স্টাইলটি ব্যবহার করেছিলেন তা অনুসরণ করুন)", "secondary_text": "" },
    { "primary_text": "পড়ার সময় আপনার শক্তির স্তর বজায় রাখুন (বাক্যের শুরুতে একই শক্তি রাখার চেষ্টা করুন এবং শেষ পর্যন্ত এটি চালিয়ে যান)", "secondary_text": "" }
]

const scriptReturnedRows = [
    { 'reason': 'অপ্রমিত', 'description': "এক বা একাধিক শব্দের উচ্চারণ প্রমিত ছিল না" },
    { 'reason': 'স্ক্রিপ্ট-স্পিচে অমিল', 'description': 'পড়া যদি স্ক্রিপ্ট অনুসারে না হয়' },

    { 'reason': 'অযাচিত পজ', 'description': 'যেখানে কোনো বিরামচিহ্ন ছিল না, সেখানে অপ্রয়োজনীয় বিরতি' },
    { 'reason': 'বিরাম প্রয়োজন', 'description': "বিরাম চিহ্ন অনুযায়ী বিরতি প্রদান করা হয়নি" },

    { 'reason': 'খুব দ্রুত', 'description': 'পড়ার ধরন খুব দ্রুত ছিল' },
    { 'reason': 'বেশি স্লো', 'description': 'পড়ার ধরন খুব ধীর ছিল' },

    { 'reason': 'বেশি লাউড', 'description': 'খুব জোরে পড়া হয়েছে' },
    { 'reason': 'ভলিউম কম', 'description': 'খুবই আস্তে রেকর্ড হয়েছে' },

    { 'reason': 'নয়েজ', 'description': 'কোনো স্পিচ নেই, শুধু আওয়াজ' },
    { 'reason': 'হাঁচি/কাশি/শ্বাস', 'description': 'শ্বাস প্রশ্বাসের শব্দ / হাঁচি / কাশি রেকর্ড হয়েছে' },

    { 'reason': 'অযাচিত আওয়াজ', 'description': "অন্যান্য ব্যক্তির কণ্ঠস্বরও রেকর্ড হয়েছে" },
    { 'reason': 'ফিলার পজ', 'description': 'পড়ার সময় ফিলার ব্যবহৃত হয়েছিল (অ্যা/উম) ' },

    { 'reason': 'অসম্পূর্ণ', 'description': 'স্ক্রিপ্টের অসম্পূর্ণ পাঠ' },
    { 'reason': 'পুনরাবৃত্তি', 'description': 'বাক্যের সম্পূর্ণ অংশ বা একটি অংশ পুনরাবৃত্তি করা হয়েছিল' },
    { 'reason': 'প্রতিধ্বনি', 'description': 'ব্যাকগ্রাউন্ডে প্রতিধ্বনি / ইকো রয়েছে' },
    { 'reason': 'যান্ত্রিক শব্দ', 'description': 'ভয়েস রোবোটিক বা যান্ত্রিক শব্দ পাওয়া গেছে' },

    { 'reason': 'প্রতিকূল পরিবেশ', 'description': 'পরিবেশগত শব্দ; যেমন—ঝড়, বৃষ্টি, বাতাসের শব্দ রয়েছে' },
    { 'reason': 'আপ-ডাউন', 'description': 'কথা রেকর্ড করার সময় কণ্ঠস্বরের অপ্রত্যাশিত উত্থান ও পতন' },
    { 'reason': 'অন্যান্য', 'description': 'বিবিধ' }
];

export default function ReadingRulesPage() {
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    return (
        <>
            <Box sx={{ height: { xs: "175vh", md: "100vh" }, weight: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "top", backgroundColor: "#FFFFFF" }}>
                <Box sx={{ width: "100%", height: { xs: "3%", md: "8%" }, border: "0px solid blue" }}>
                    <NavbarResponsive />
                </Box>
                <Box sx={{ width: "100%", height: { xs: "94%", md: "86%" }, border: "0px solid red", display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                    <Box sx={{ width: { xs: "100%", md: "50%" }, height: { xs: "50%", md: "100%" }, border: "0px solid red", display: "flex", flexDirection: "column" }}>
                        <Box sx={{ width: "100%", height: "10%", border: "0px solid red" }}>
                            <Alert sx={{ height: "100%", fontFamily: 'Noto Serif Bengali, serif', fontSize: '112%', fontWeight: 'bold' }} severity="success">পড়ার নিয়মাবলি</Alert>
                        </Box>
                        <Box sx={{ width: "100%", height: "90%", border: "0px solid blue", overflowY: "auto" }}>
                            <Demo>
                                <List dense={true}>
                                    {readingRules.map((item: any, index: any) => (
                                        <ListItem key={index}>
                                            <ListItemIcon>
                                                {/* <EastIcon /> */}
                                                <DiamondIcon color="success" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{ fontFamily: 'Noto Serif Bengali, serif', fontWeight: "bold" }}>{item.primary_text}</Typography>}
                                                secondary={secondary ? item.secondary_text : null}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Demo>
                        </Box>
                    </Box>
                    <Box sx={{ width: { xs: "100%", md: "50%" }, height: { xs: "50%", md: "100%" }, border: "0px solid green", display: "flex", flexDirection: "column" }}>
                        <Box sx={{ width: "100%", height: "10%", border: "0px solid red" }}>
                            <Alert severity="error" sx={{ height: "100%", fontFamily: 'Noto Serif Bengali, serif', fontSize: '112%', fontWeight: 'bold' }}>যেসব কারণে স্ক্রিপ্ট ফেরত যেতে পারে —</Alert>
                        </Box>
                        <Box sx={{ width: "100%", height: "90%", border: "0px solid blue" }}>
                            <TableContainer component={Paper} sx={{ width: '100%', height: "100%", overflowY: "auto" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>কারণ</StyledTableCell>
                                            <StyledTableCell>বিবরণ</StyledTableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {scriptReturnedRows.map((row) => (
                                            <StyledTableRow
                                                key={row.reason}
                                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="row" sx={{ fontSize: "100%", fontWeight: 'bold', fontStyle: "italic" }}>
                                                    {row.reason}
                                                </StyledTableCell>

                                                <TableCell align="justify" sx={{ fontFamily: "Noto Serif Bengali, serif", fontSize: "110%", fontWeight: 'bold' }}>{row.description}</TableCell>

                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", width: "100%", height: { xs: "3%", md: "6%" }, border: "0px solid blue" }}>
                    <Footer />
                </Box>
            </Box>
        </>



    );
}
